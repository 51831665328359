import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import '../styles.scss'
import './warranty.scss'
export default function Warranty() {
    return (
        <Layout>
            <PaddedLayout>
                <h1>Warranty Policy</h1>
                <h4 className="under-main-heading">Effective Date: June 1, 2020</h4>
                <p>RPS Corporation warrants its Machines and Original Equipment Accessories to be free of manufacturer’s defects in materials or workmanship for the periods specified below. Warranty will be granted at the sole discretion of RPS Corporation to an Authorized Local Distributor or Service Center and is subject to final claim and parts review by RPS Corporation and its vendors. This policy is effective April 1, 2020 and RPS Corporation reserves the right to change its warranty policy without notice. <a href="javascript:void(0)">Download PDF</a></p>
                <h5 className="sub-heading">Coverage, Exclusions and Limitations:</h5>
                <div className="coverage-table-wrapper"> 
                    <div>
                        {/* HEADINGS */}
                        <div className="title">Battery Powered Machines</div>
                        <div className="title">Parts</div>
                        <div className="title">Labor</div>
                        <div className="title">Travel</div>
                        <div className="title">Poly Tanks</div>

                        {/* SCRUBBERS */}
                        <div className="info-label">Scrubbers</div>
                        <div className="info">3 Years<span>1</span></div>
                        <div className="info">2 Years</div>
                        <div className="info">90 Days<span>2</span></div>
                        <div className="info">7 Years<span>3,4</span></div>

                        {/* SWEEPER */}
                        <div className="info-label">Sweepers</div>
                        <div className="info">3 Years<span>1</span></div>
                        <div className="info">2 Years</div>
                        <div className="info">90 Days<span>2</span></div>
                        <div className="info">-</div>

                        {/* BURNISHERS */}
                        <div className="info-label">Burnishers</div>
                        <div className="info">3 Years<span>1</span></div>
                        <div className="info">2 Years</div>
                        <div className="info">90 Days<span>2</span></div>
                        <div className="info">-</div>

                        {/* HEADINGS */}
                        <div className="title">FLOOR MACHINES</div>
                        <div className="title">Parts</div>
                        <div className="title">Labor</div>
                        <div className="title"></div>
                        <div className="title"></div>

                        {/* FLOOR MACHINES */}
                        <div className="info-label">Floor Machines</div>
                        <div className="info">1 Year</div>
                        <div className="info">90 Days</div>
                        <div className="info"></div>
                        <div className="info"></div>

                        {/* ZERO WALL MOUNT */}
                        <div className="info-label">Zero Wall Mount</div>
                        <div className="info">180 Days</div>
                        <div className="info"></div>
                        <div className="info"></div>
                        <div className="info"></div>
                        </div>
                        {/* NOTES */}
                        <div className="notes">
                            <div className="notes-title">Notes</div>
                            <div className="info-label">1</div>
                            <div className="info"> - Or 1,500 hours (whichever comes first)</div>

                            <div className="info-label">2</div>
                            <div className="info"> - 90 Days (Up to 150 miles total distance)</div>

                            <div className="info-label">3</div>
                            <div className="info"> - 7 Years or 3,000 hours (whichever comes first)</div>

                            <div className="info-label">4</div>
                            <div className="info"> - Water must not exceed 135°F/57°C</div>
                        </div>
                </div>
                <div className="explanation-wrapper">
                    <h6>New Parts</h6>
                    <p>90 day warranty on parts from RPS Corporation (when installed by a Local Authorized Distributor)</p>
                    <h6>Valadity</h6>
                    <p>Machine is serviced by Factory Authorized Personnel and a fully completed Machine Delivery Form
                        (online or fax with signatures) is on record at RPS Corporation.</p>
                    <h6>LIMITATION:</h6>
                    <p>Warranty will begin on date of machine installation to end-user or 6 months after shipment from RPS
                        Corporation to the Distributor (whichever comes first).</p>
                </div>
                    <h5 className="sub-heading">Batteries</h5>
                    <p>This warranty does not cover routine maintenance, adjustments or parts damaged from abuse, neglect, damage in transit,
                    overloading the capacity of the machine, improper use of the machine, lack of scheduled “daily, weekly, monthly” maintenance in accordance with our published Preventative Maintenance (PM) Sheets, or wear items such as, but not limited to: EDGE / ORBITZ Grip Face, Squeegee & Wiper Blades, Skirts & Curtains, Caster Wheels, Squeegee Wheels, Bumpers, Lights (Strobe, Headlights, or Bulbs), Chains, Belts, Filters, Screens, Vacuum Bags, Motor Brushes and/or commutator wear, Brooms, Brushes, Pads, Pad Retainers, Hoses, Tubing, Drive Tires, Foam Filled Wheels, Tubes, Valve Stems, Rubber Floor Seals, Gaskets, Grip Handles, Vacuum motors with evidence of moisture or debris intrusion or greater than 400 hours, Machine Cord(s), and Batteries.</p>
                    <p>Battery damage from lack of maintenance/lack of water, use of a non-OEM charger, or use of non-distilled water is NOT covered under this warranty. </p>
                    <p>
                    Battery terminals and cable connections are covered for 30 days from the shipment of the machine. (Battery terminals and cables should be inspected, cleaned and secured upon installation of the machine and then every 30 days thereafter. Battery terminals are torqued to Battery Manufacturer specifications [60in/lbs - 150in/lbs dependent on battery]. Please refer to the Battery manufacturers website for more information.)
                    </p>
            </PaddedLayout>
        </Layout>
    )
}
